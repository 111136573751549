import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Carousel } from "react-bootstrap"
import ReactPlayer from "react-player"
import ReactMarkdown from "react-markdown"
import urlSlug from "url-slug"
import { useLayoutEffect } from "react"

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

const Carousels = ({ carousel }) => {
  const [slideStates, setSlidesState] = useState(null)
  const [width, height] = useWindowSize()
  useEffect(() => {
    setSlidesState(
      carousel
        ? (carousel.slide?.filter(slide => slide.visible === true)).length
        : null
    )
  }, [carousel])

  return (
    <>
      {carousel?.visible && (
        <Carousel
          className="carousel custom-carousel flex-grow-1 d-flex h-100"
          slide={slideStates > 1}
          indicators={slideStates > 1}
          controls={slideStates > 1}
        >
          {carousel.slide.map(slide => {
            if (slide.visible) {
              return (
                <Carousel.Item
                  interval={10000}
                  key={slide.id}
                  className="h-100"
                >
                  <div
                    className="h-100 d-flex video-mobile"
                    style={{
                      backgroundImage: slide.backgroundImage.url
                        ? `url(${slide.backgroundImage.url})`
                        : "",
                    }}
                  >
                    {/* video */}
                    {slide?.backgroundVideo && width >= 991 && (
                      <div className="position-absolute video-background w-100 h-100 d-flex align-items-center justify-content-between">
                        <div className=" w-100 embed-responsive embed-responsive-16by9">
                          <ReactPlayer
                            url={slide.backgroundVideo.url}
                            playing={true}
                            muted={true}
                            loop={true}
                            className="fade-in-image"
                          ></ReactPlayer>
                        </div>
                      </div>
                    )}
                    {/* contenido */}
                    <div className=" h-100 slide-background flex-grow-1 d-flex flex-column justify-content-center text-white">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="p-5 info-cont">
                              <ReactMarkdown
                                className="format-markdown"
                                source={slide.content}
                              />
                              {slide.career ||
                              slide.novelty ||
                              slide.diary ||
                              slide.url ? (
                                <ButtonCarousel
                                  career={slide.career}
                                  novelty={slide.novelty}
                                  diary={slide.diary}
                                  url={slide.url}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              )
            } else {
              return null
            }
          })}
        </Carousel>
      )}
    </>
  )
}

const ButtonCarousel = ({ career, novelty, diary, url }) => {
  let component = null
  if (career) {
    component = (
      <Link
        to={`/carreras/${urlSlug(career.slug)}`}
        key={`/carreras/${career.slug}`}
        className="btn btn-primary fit-content"
      >
        Ver carrera
      </Link>
    )
  } else if (novelty) {
    component = (
      <Link
        to={`/noticias/${urlSlug(novelty.title)}`}
        key={`/noticias/${urlSlug(novelty.title)}`}
        className="btn btn-primary fit-content"
      >
        Ver noticia
      </Link>
    )
  } else if (diary) {
    component = (
      <Link
        to={`/agenda/${urlSlug(diary.title)}`}
        key={`/agenda/${urlSlug(diary.title)}`}
        className="btn btn-primary fit-content"
      >
        Ver evento
      </Link>
    )
  } else if (url) {
    component = (
      <Link
        to={`${url}`}
        key={`${url}`}
        className="btn btn-primary fit-content"
      >
        Visitar
      </Link>
    )
  }
  return component
}

export default React.memo(Carousels)
