import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import defaultImage from '../images/slider-01.jpg'
import {faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import urlSlug from 'url-slug'


const Diaries = ({ diaries, diaryTitle = "ICDA" }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="flex-grow-1">
          <h5 className="text-uppercase">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2"/>Agenda <span className="font-weight-bold text-main">{diaryTitle}</span>
          </h5>
        </div>
        <Link to="/agenda" className="btn btn-sm btn-outline-dark ml-2">Ver todos</Link>
      </div>
      <div className="row">
        {diaries.edges.map(document => (
          <div className="col-12 col-md-3 pb-4 mb-3" key={document.node.id}>
            <div className="d-flex flex-column h-100">
              <div className="diary-feed-img" style={{ backgroundImage: `url(${document.node.imageResume?.image ? document.node.imageResume.image.url : defaultImage}` }}></div>
              <div className="bg-main px-2 py-1 text-white mb-2">
                {document.node.diary_category?.name ? document.node.diary_category.name : 'General'}
              </div>
              <p className="text-muted mb-0">
                {document.node.date}
              </p>
              <p>
                {document.node.place}
              </p>
              <h5 className="mb-3">
                {document.node.title}
              </h5>
              <Link to={`/agenda/${urlSlug(document.node.title)}`} className="btn btn-outline-primary w-auto mt-auto">Ver más</Link>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Diaries
