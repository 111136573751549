import React from "react"
import { graphql } from "gatsby"
import { Col, Row } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousels from "../components/carousel"
import GeneralForm from "../components/general-form"
import Map from "../components/map"
import Testimonials from "../components/testimonials"
import News from "../components/news"
import AnimatedText from "../components/animated-text"
import Diaries from "../components/diaries-container"
import { PdfPreview } from "./pdfPreview"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="ICDA - Universidad Católica" />
      <section>
        {data.strapiAcademicUnits.header ? (
          <div className="d-flex flex-column header-carousel">
            <Carousels carousel={data.strapiAcademicUnits.header} />
          </div>
        ) : null}
        <AnimatedText />
        {data.strapiAcademicUnits.carousel ? (
          <div className="d-flex flex-column banner-carousel container">
            <Carousels carousel={data.strapiAcademicUnits.carousel} />
          </div>
        ) : null}
        <div className="container">
          {data.allStrapiNovelties &&
          data.allStrapiNovelties.edges.length > 0 ? (
            <div className="my-5">
              <News news={data.allStrapiNovelties.edges} />
            </div>
          ) : null}
          {data.allStrapiDiaries && data.allStrapiDiaries.edges.length > 0 ? (
            <div className="my-5">
              <Diaries diaries={data.allStrapiDiaries} />
            </div>
          ) : null}
          {data.strapiAcademicUnits.testimonials &&
          data.strapiAcademicUnits.testimonials.length > 0 ? (
            <div className="my-5">
              <Testimonials
                testimonials={data.strapiAcademicUnits.testimonials}
              />
            </div>
          ) : null}

          <PdfPreview pdfPreview={data.strapiAcademicUnits.pdf.pdf}/>

          <Row>
            <Col xs={12} md={6}>
              <GeneralForm careers={data.dropdownCareerForm} />
            </Col>
            <Col xs={12} md={6}>
              <div className="mb-3">
                <Map />
              </div>
              <div className="d-flex">
                <h6>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                </h6>
                <h6>Obispo Trejo 323, Córdoba, Córdoba, Argentina</h6>
              </div>
              <div className="d-flex">
                <h6>
                  <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
                </h6>
                <h6>(+54) 351 2026171</h6>
              </div>
            </Col>
          </Row>
          <div className="py-5">
            <h4>Una escuela global y conectada</h4>
            <div className="row align-items-center justify-content-center">
              {data.allFile.edges.map(image => (
                <div
                  className="col-6 col-lg-3 col-md-4 col-sm-6 d-flex"
                  key={image.node.id}
                >
                  <img
                    className="prefooter-logos"
                    style={{ objectFit: "cover" }}
                    src={image.node.publicURL}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query {
    strapiAcademicUnits(name: { eq: "ICDA" }) {
      header {
        id
        slide {
          id
          name
          content
          imageDescription
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
        }
        visible
      }
      testimonials {
        name
        testimonial {
          company
          content
          position
        }
      }
      carousel {
        id
        slide {
          id
          name
          content
          imageDescription
          visible
          career {
            slug
          }
          diary {
            title
          }
          novelty {
            title
          }
          url
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
        }
        visible
      }
      pdf {
        pdf {
          code
          id
          title
        }
      }
    }
    allStrapiNovelties(
      limit: 4
      filter: { academic_unit: { name: { eq: "ICDA" } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "DD MMMM, YYYY", locale: "es")
          summary
          title
          content
          imageResume {
            image {
              id
              url
            }
          }
          published_at(formatString: "DD MMMM, YYYY", locale: "es")
          novelties_categories {
            name
          }
        }
      }
    }
    allStrapiTestimonials(filter: { academic_unit: { name: { eq: "ICDA" } } }) {
      edges {
        node {
          id
          name
          testimonial {
            company
            content
            position
            visible
          }
        }
      }
    }
    allStrapiIcdaCareers(filter: { main: { id: { eq: 1 } } }) {
      edges {
        node {
          main {
            headerImages {
              image {
                url
              }
            }
            id
            title
            slogan
          }
        }
      }
    }
    dropdownCareerForm: allStrapiIcdaCareers(
      filter: { academic_unit: { name: { eq: "ICDA" } } }
    ) {
      edges {
        node {
          name
          webcentrix {
            career
            academicUnit
            level
            agent
          }
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          id
          publicURL
        }
      }
    }
    allStrapiDiaries(
      limit: 4
      filter: { academic_unit: { name: { eq: "ICDA" } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          summary
          title
          place
          diary_category {
            name
          }
          cost
          content
          date(formatString: "DD MMMM, YYYY", locale: "es")
          headerImages {
            image {
              url
            }
          }
          imageResume {
            image {
              id
              url
            }
          }
        }
      }
    }
  }
`
