import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile } from "@fortawesome/free-solid-svg-icons"

export const PdfPreview = ({ pdfPreview }) => {
  
  // const pdfPreview = {
  //   title: "Programa Institucional",
  //   code: `<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=programa_institucional_3_&u=uccoficial"></iframe></div>`,  
  //   id: 1
  // }

  useEffect(() => {
    const pdfContainer = document.getElementById(
      `pdf-container-${pdfPreview.id}`
    )
    const codePdf = pdfPreview.code
      .replace('height:0', 'height:400px')
      .replace('max(60%', 'max(40%')
    pdfContainer.innerHTML = codePdf
  }, [pdfPreview?.code])

  return (
    <div className="mb-5">
      <div className="flex-grow-1">
        <h5 className="text-uppercase">
          <FontAwesomeIcon icon={faFile} className="mr-2" />
          {pdfPreview?.title} <span className="font-weight-bold text-main">ICDA</span>
        </h5>
      </div>
      <div
        id={`pdf-container-${pdfPreview?.id}`}
        className=" w-100 shadow-sm rounded"
      ></div>
    </div>
  )
}

PdfPreview.propTypes = {
  pdfPreview: PropTypes.object.isRequired
}
