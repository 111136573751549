import React from 'react';
import TextLoop from "react-text-loop";

const AnimatedText = () => {
    return (
        <div className='container animated-text text-uppercase text-center mt-5'>
            <h1>
                Pensá en alguien {" "}
                <TextLoop className='text-primary loop-text'>
                    <span>Estratégico</span>
                    <span>Resiliente</span>
                    <span>Talentosa</span>
                    <span>Innovador</span>
                    <span>Analítica</span>
                    <span>Ingenioso</span>
                    <span>Negociadora</span>
                    <span>Comprometido</span>
                    <span>Original</span>
                    <span>Creativo</span>
                    <span>Líder</span>
                </TextLoop>{", "}
                VOS podés ser lo que imaginás
            </h1>
        </div>
    );
}

export default AnimatedText