import React from "react"
import { Link } from "gatsby"
import urlSlug from "url-slug"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"
import defaultImage from "../images/slider-01.jpg"

const News = ({ news, newTitle = "ICDA" }) => {
  return (
    <div className="my-5">
      <div className="d-flex align-items-center mb-3">
        <div className="flex-grow-1">
          <h5 className="text-uppercase">
            <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
            Noticias <span className="font-weight-bold text-main">{newTitle}</span>
          </h5>
        </div>
        <Link to="/noticias" className="btn btn-sm btn-outline-dark ml-2">
          Ver todas
        </Link>
      </div>
      <div className="row">
        {news.map(({ node: newItem }) => (
          <div className="pb-4 col-12 col-md-6" key={newItem.id}>
            <div
              className="novelty-feed-img h-100"
              style={{
                backgroundImage: `url(${
                  newItem.imageResume?.image
                    ? newItem.imageResume.image.url
                    : defaultImage
                }`,
              }}
            >
              <div className=" p-5 text-white d-flex flex-column background h-100">
                {/* Fecha */}
                <h6>
                  <small>{newItem.date}</small>
                </h6>
                {/* Etiqueta */}
                <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">
                  {newItem.novelties_categories.map((categories, i) => {
                    if (categories?.name) { 
                      if ((newItem.novelties_categories.length - 1) > i) {
                        return categories.name + " - "
                      } else {
                        return categories.name
                      }
                    } else {
                      return "General"
                    } 
                  })}             
                </div>
                <h4 className="mb-3 mt-2">{newItem.title}</h4>
                <Link
                  to={`/noticias/${urlSlug(newItem.title)}`}
                  className="btn btn-outline-light mt-auto fit-content"
                >
                  Ver más
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default News
