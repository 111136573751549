import React, { useRef, useState } from "react"
import { Alert } from "react-bootstrap"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from "axios";

const GeneralForm = ({ careers }) => {
  let academicUnit = 'ICDA'
  const [showMessage, setShowMessage] = useState(false);
  const agent = '18657'

  const btn_scrollMessage = useRef(null)
  const btn_submit = useRef(null)

  const [loading, setLoading] = useState(false)

  const executeScroll = () => btn_scrollMessage.current.scrollIntoView({ block: "center", behavior: "smooth" })

  return (
    <>
      <Formik
        initialValues={{
          agent,
          academicUnit,
          career: '',
          name: '',
          email: '',
        }}
        onSubmit={((values, actions) => {
          setLoading(true)
          btn_submit.current.classList.add('loading');
          axios.post('https://strapi.uccor.xyz/wc-create-lead', values)
            .then((data) => {
              btn_submit.current.classList.remove('loading');
              actions.setSubmitting(false);
              actions.resetForm();
              setShowMessage(true)
              executeScroll();
              setLoading(false)
            })
            .catch((e) => { alert(e) })
        })}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          const errors = {};
          if (!values.name) {
            errors.name = 'El nombre es requerido'
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email = 'El formato de email ingresado no es correcto'
          }
          if (!values.career) {
            errors.career = 'Seleccione un motivo'
          }
          return errors;
        }}

      >
        {() => (
          <div>
            <h4>Contactanos</h4>
            <p>Dejanos un mensaje</p>
            <Form method='POST' className="my-4">
              <div className="form-group">
                <label htmlFor="name">Nombre y Apellido</label>
                <Field name="name" className="form-control" />
                <ErrorMessage name="name" component="div" className="text-danger" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field name="email" className="form-control" />
                <ErrorMessage name="email" component="div" className="text-danger" />
              </div>
              <div className="form-group">
                <label htmlFor="career">Motivo</label>
                <Field name="career" as="select" className="form-control text-uppercase">
                  <Field as="option" key={`career-option`} className="text-uppercase"></Field>
                  {
                    careers.edges.map(({ node }, i) => (
                      <Field as="option" value={node.name} key={`career-option-${i}`} className="text-uppercase">{node.name}</Field>
                      ))
                    }
                </Field>
                <ErrorMessage name="career" component="div" className="text-danger" />
              </div>
              <button ref={btn_submit} className="btn btn-primary" type="submit" disabled={loading}>
                Enviar
                  <span className="spinner-border spinner-border-sm ml-2 show-on-loading" role="status" aria-hidden="true"></span>
                <span className="sr-only">Loading...</span>
              </button>
            </Form>
          </div>
        )}
      </Formik>
      {
        showMessage && (
          <div className="my-5">
            <Alert variant="success" ref={btn_scrollMessage} className="text-center" onClose={() => setShowMessage(false)} dismissible>
              <Alert.Heading>¡Su consulta fue enviada con exito!</Alert.Heading>
              <p className="mb-0">
                Nos contactaremos a la brevedad, muchas gracias.
            </p>
            </Alert>
          </div>
        )
      }
    </>
  )
}

export default GeneralForm